import {ethers} from 'ethers'
import QRCodeModal from '@walletconnect/qrcode-modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Vue from 'vue'
import router from '@/router'

const store = {
    state: {
        browserWalletAvailable: window.ethereum !== undefined,

        accountData: {
            address: null
        },

        connectedWalletData: {
            chainId: null,
            rawProvider: null,
            ethersProvider: null,
            signer: null,
            usedProvider: 0,
            accountsRequested: false,
            walletConnectProvider: null
        },
        created: false
    },
    getters: {
      accountData(state) {
        return state.accountData
      }
    },
    mutations: {},
    actions: {
        async disconnectWallet({state}) {
            if (state.connectedWalletData.usedProvider === 2) {
                state.connectedWalletData.walletConnectProvider.close()
            }
            localStorage.removeItem('last_connection')
            window.location.reload()
        },
        async connectMetamask({state, dispatch}) {

            if (state.connectedWalletData.accountsRequested) return

            try {

                state.connectedWalletData.rawProvider = window.ethereum
                state.connectedWalletData.ethersProvider = new ethers.providers.Web3Provider(window.ethereum)
                state.connectedWalletData.usedProvider = 1

                state.connectedWalletData.accountsRequested = true
                const addresses = await window.ethereum.request({method: 'eth_requestAccounts'})
                state.connectedWalletData.accountsRequested = false

                if (addresses.length > 0) {
                    const network = await state.connectedWalletData.ethersProvider.getNetwork()
                    await dispatch('handleNetworkSet', ethers.utils.hexlify(network.chainId))
                    await dispatch('walletAddressChanged', addresses[0])
                }

                window.ethereum.on('chainChanged', async(e) => {
                    if (state.connectedWalletData.usedProvider !== 1) return
                    state.connectedWalletData.ethersProvider = new ethers.providers.Web3Provider(window.ethereum)
                    await dispatch('handleNetworkSet', e)
                })

                window.ethereum.on('accountsChanged', async(accounts) => {
                    if (state.connectedWalletData.usedProvider !== 1) return
                    await dispatch('walletAddressChanged', (accounts.length > 0) ? accounts[0] : null)
                })

                localStorage.setItem('last_connection', 'window.ethereum')

            } catch (e) {
                Vue.prototype.$printError('There was an error connecting to Metamask. Please refresh your website!')
            }
        },
        async connectWalletConnect({state, dispatch}) {

            state.connectedWalletData.walletConnectProvider = new WalletConnectProvider({
                rpc: {
                    56: 'https://bsc-dataseed.binance.org/'
                },
                bridge: 'https://bridge.walletconnect.org',
                chainId: 56,
                qrcodeModal: QRCodeModal
            })

            try {

                await state.connectedWalletData.walletConnectProvider.enable()

                state.connectedWalletData.rawProvider = state.connectedWalletData.walletConnectProvider
                state.connectedWalletData.ethersProvider = new ethers.providers.Web3Provider(state.connectedWalletData.walletConnectProvider)
                state.connectedWalletData.usedProvider = 2

                const addresses = state.connectedWalletData.walletConnectProvider.accounts
                if (addresses.length > 0) {
                    const network = await state.connectedWalletData.ethersProvider.getNetwork()
                    await dispatch('handleNetworkSet', ethers.utils.hexlify(network.chainId))
                    await dispatch('walletAddressChanged', addresses[0])
                }

                state.connectedWalletData.walletConnectProvider.on('accountsChanged', async(accounts) => {
                    if (state.connectedWalletData.usedProvider !== 2) return
                    await dispatch('walletAddressChanged', (accounts.length > 0) ? accounts[0] : null)
                })

                state.connectedWalletData.walletConnectProvider.on('chainChanged', async(chainId) => {
                    if (state.connectedWalletData.usedProvider !== 2) return
                    state.connectedWalletData.ethersProvider = new ethers.providers.Web3Provider(state.connectedWalletData.walletConnectProvider)
                    await dispatch('handleNetworkSet', ethers.utils.hexlify(chainId))
                })

                state.connectedWalletData.walletConnectProvider.on('disconnect', () => {
                    window.location.reload()
                })

                localStorage.setItem('last_connection', 'wc')

            } catch (e) {
                Vue.prototype.$printError('There was an error connecting to Wallet Connect. Please refresh your website!')
            }
        },
        async walletAddressChanged({state, dispatch}, address) {

            try {
                if (address) {
                    state.accountData.address = ethers.utils.getAddress(address)
                    state.connectedWalletData.signer = state.connectedWalletData.ethersProvider.getSigner()
                } else {
                    state.accountData.address = address
                }
                if (state.created) {
                  dispatch('setLogout')
                  if (router.currentRoute.name && router.currentRoute.name !== 'login') {
                    await router.push({name: 'login'})
                  }
                }

                state.created = true
            } catch (e) {
                Vue.prototype.$printError('There was an error retreiving data from your wallet. Please refresh your website!')
            }
        },
        async handleNetworkSet({state}, chainId) {
            try {
                state.connectedWalletData.chainId = chainId
                state.connectedWalletData.signer = state.connectedWalletData.ethersProvider.getSigner()
            } catch (e) {
                Vue.prototype.$printError('There was an error connecting to your wallet. Please refresh your website!')
            }
        }
    }
}

export default store
