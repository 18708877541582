import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dayjs from 'dayjs'

// Global Components
import './global-components'
import '@/libs/chains'
import '@/libs/font-awesome'
import '@/libs/toast'
import VueClipboard from 'vue-clipboard2'

// styles
import '@/assets/scss/style.scss'
import '@/assets/css/style.css'
import '@/assets/scss/overrides/bootstrap.scss'
import '@/assets/scss/overrides/vue2-datepicker.scss'
import '@/assets/scss/overrides/vue-select.scss'

Vue.use(VueClipboard)
dayjs.extend(require('dayjs/plugin/advancedFormat'))
Vue.prototype.$dayjs = dayjs

// axios
import axios from './libs/axios.js'

Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render(h) { return h(App) }
}).$mount('#app')
