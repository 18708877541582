import axios from 'axios'
import Vue from 'vue'
import router from '@/router'

export default {
  state: {
    loggedIn: false,
    accountStatus: -1,
    userData: {}
  },
  getters: {
    loggedIn(state) {
      return state.loggedIn
    },
    accountStatus(state) {
      return state.accountStatus
    }
  },
  mutations: {
    SET_LOG_IN(state) {
      state.loggedIn = true
    },
    SET_LOG_OUT(state) {
      state.loggedIn = false
      state.accountStatus = -1
    },
    SET_ACCOUNT_STATUS(state, payload) {
      state.accountStatus = payload
    }
  },
  actions: {
    setLogin({ commit }) {
      commit('SET_LOG_IN')
      localStorage.setItem('loggedIn', 'true')
    },
    setLogout({ commit }) {
      commit('SET_LOG_OUT')
      localStorage.setItem('loggedIn', 'false')
    },
    setAccountStatus({commit}, payload) {
      commit('SET_ACCOUNT_STATUS', payload)
    },
    async getAuthorRequestStatus({dispatch}, payload) {
      try {
        const redirect = payload

        const response = await axios.get('/api/author/v1/author/get_status')
        dispatch('setAccountStatus', response.data)

        if (redirect) {
          if (response.data === 0) {
            await router.push({name: 'become-an-author'})
          } else if (response.data === 2) { // if author request is accepted, redirect to dashboard page
            await router.push({name: 'home'})
          } else if (response.data === 1 || response.data === 3) { // rejected or waiting for response
            await router.push({name: 'account-status'})
          }
        }

      } catch (e) {
        if (e.response && e.response.status !== 401) Vue.prototype.$printError(`${e.code} ${e.message}`)
        if (e && e.name !== 'NavigationDuplicated') {
          dispatch('setLogout')
          if (router.currentRoute.name && router.currentRoute.name !== 'login') await router.push({name: 'login'})
        }
      }
    }
  }
}

