// axios
import axios from 'axios'
import router from '../router/index.js'

const baseURL = ((process.env.NODE_ENV !== 'production') ? `${window.location.origin}/` : '/')

// ADDS BEARER JWT TO EVERY REQUEST
axios.interceptors.request.use(
  config => {
    const jwt = localStorage.getItem('accessToken')
    if (jwt !== '') config.headers.common.Authorization = `Bearer ${jwt}`
    return config
  },
  error => Promise.reject(error)
)

axios.interceptors.response.use(
  response => {
    return Promise.resolve(response)
  },
  error => {
    if (error.response.status === 401) {
      router.push({ name: 'login' })
    }
    return Promise.reject(error)
  }
)

export default axios.create({
  baseURL,
  timeout: 180 * 1000 // 180 seconds * 1000ms
  // You can add your headers here
})
