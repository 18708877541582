import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes'
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars'
import {faEllipsisVertical} from '@fortawesome/free-solid-svg-icons/faEllipsisVertical'
import {faShareNodes} from '@fortawesome/free-solid-svg-icons/faShareNodes'
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch'
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons/faLinkedinIn'
import {faTelegram} from '@fortawesome/free-brands-svg-icons/faTelegram'
import {faTwitter} from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import {faCircleCheck} from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import {faCircleExclamation} from '@fortawesome/free-solid-svg-icons/faCircleExclamation'
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark'

library.add(
  faTimes,
  faBars,
  faEllipsisVertical,
  faShareNodes,
  faSearch,
  faLinkedinIn,
  faTelegram,
  faTwitter,
  faChevronDown,
  faChevronRight,
  faClock,
  faCircleCheck,
  faCircleExclamation,
  faXmark
)

// Register the component globally
Vue.component('fa', FontAwesomeIcon)
