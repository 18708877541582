import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Dashboard'),
    meta: {
      requiresAuth: true,
      requiresApprovedAccount: true
    }
  },
  {
    path: '/account-status',
    name: 'account-status',
    component: () => import('@/views/AccountStatus'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/author',
    name: 'author',
    component: () => import('@/views/Author/AuthorProfile'),
    meta: {
      requiresAuth: true,
      requiresApprovedAccount: true
    }
  },
  {
    path: '/my-collections',
    name: 'my-collections',
    component: () => import('@/views/MyCollections/MyCollections'),
    meta: {
      requiresAuth: true,
      requiresApprovedAccount: true
    }
  },
  {
    path: '/my-collection/:collection_id',
    name: 'my-collection',
    component: () => import('@/views/MyCollections/MyCollection'),
    meta: {
      requiresAuth: true,
      requiresApprovedAccount: true
    }
  },
  {
    path: '/add-collection',
    name: 'add-collection',
    component: () => import('@/views/AddCollection'),
    meta: {
      requiresAuth: true,
      requiresApprovedAccount: true
    }
  },
  {
    path: '/add-nft',
    name: 'add-nft',
    component: () => import('@/views/AddNft'),
    meta: {
      requiresAuth: true,
      requiresApprovedAccount: true
    }
  },
  {
    path: '/my-nfts/:nft_id',
    name: 'my-nft',
    component: () => import('@/views/MyNft/MyNft.vue'),
    meta: {
      requiresAuth: true,
      requiresApprovedAccount: true
    }
  },
  {
    path: '/my-nfts',
    name: 'my-nfts',
    component: () => import('@/views/MyNfts/MyNfts'),
    meta: {
      requiresAuth: true,
      requiresApprovedAccount: true
    }
  },
  {
    path: '/become-an-author',
    name: 'become-an-author',
    component: () => import('@/views/BecomeAnAuthor/BecomeAnAuthor'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login')
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue')
  },
  {
    path: '*',
    redirect: 'error-404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('loggedIn')
  if (loggedIn !== 'undefined' && loggedIn && loggedIn === 'true') {
    store.state.user.loggedIn = true
  }

  if (to.meta && to.meta.requiresAuth && !store.state.user.loggedIn) {
    return next('/login')
  }

  next()
})

export default router
