<template>
    <div id="app" class="pb-24 lg:pb-12 md:pt-8 mx-4 sm:mx-6 md:mx-20 lg:mx-20">
        <nav v-if="$route.name !== 'login' && $route.name !== 'error-404'" class="py-4 lg:px-8">
            <div class="flex items-center justify-start lg:grid lg:grid-cols-12">
                <div class="lg:col-span-2 flex items-center">
                    <router-link to="/">
                        <img width="110px" alt="logo" :src="require('@/assets/3air_logo.svg')">
                    </router-link>
                </div>
                <div class="w-full lg:col-span-10 flex items-end">
                    <div class="ml-auto flex items-center" style="position: relative;">
                        <div v-if="$store.getters.loggedIn && $store.getters.accountData && $store.getters.accountData.address && $store.getters.accountData.address !== ''" class="address-text mr-4">
                            {{getShortAddress}}
                        </div>
                        <span class="dropdown" @focusout="!mouseover ? showLinks = false: showLinks = true">
                            <button @click="showLinks === true ? showLinks = false: showLinks = true" class="flex items-center">
                                <img v-if="author && author.image !== ''" class="img-profile" :src="`/api/author/v1/image/thumbnail_${author.image}`">
                                <img v-else class="img-profile" :src="require('@/assets/profile-image.svg')">
                            </button>
                            <div v-if="showLinks" class="links" @mouseover="mouseover = true" @mouseleave="mouseover = false">
                                <div v-if="$store.getters['accountStatus'] === 2">
                                    <router-link to="/author" class="account">{{getShortAuthorName}}</router-link>
                                    <hr class="hr"/>
                                    <div>
                                        <router-link to="/">Dashboard</router-link>
                                        <router-link to="/add-collection">Add collection</router-link>
                                        <router-link to="/add-nft">Add NFT</router-link>
                                        <router-link to="/my-collections">My collections</router-link>
                                        <router-link to="/my-nfts">My NFTs</router-link>
                                    </div>
                                    <div v-if="$store.getters['loggedIn']">
                                        <hr class="hr"/>
                                        <a class="cursor-pointer" @click="logout">Disconnect wallet</a>
                                    </div>
                                </div>
                                <div v-else>
                                    <router-link to="/account-status" class="account">Creator portal</router-link>
                                    <hr class="hr"/>
                                    <div v-if="$store.getters['loggedIn']">
                                        <a class="cursor-pointer" @click="logout">Disconnect wallet</a>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>

        </nav>
        <div class="mt-4 grid grid-cols-12 w-full h-full">
            <router-view class="lg:col-start-3 col-span-12 lg:col-span-8"/>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showLinks: false,
                mouseover: false,
                author: null
            }
        },
        methods: {
            async getAuthorData() {
                try {
                    const accountData = this.$store.getters['accountData']
                    if (accountData && accountData.address && accountData.address !== '' && this.$store.getters['accountStatus'] === 2 && this.$store.getters['loggedIn']) {
                        const response = await this.$http.get(`/api/author/v1/author/${accountData.address}`)
                        this.author = response.data
                    } else {
                        this.author = null
                    }
                } catch (error) {
                    if (error.response && error.response.status !== 404) this.$printError(`${error.response.status} ${error.response.statusText}`)
                    this.author = null
                }
            },
            async logout() {
                try {
                    await this.$http.get('/api/author/v1/logout')
                    await this.$store.dispatch('setLogout')
                    await this.$router.push({name: 'login'})
                } catch (error) {
                    this.$printError(`${error.response.status} ${error.response.statusText}`)
                } finally {
                    this.showLinks = false
                }
            },
            checkRouter() {
                const status = this.$store.getters['accountStatus']
                if (status !== -1) {
                    if (this.$route.meta && this.$route.meta.requiresApprovedAccount && status !== 2) {
                        this.$router.push({name: 'become-an-author'})
                    }
                }
            }
        },
        computed:{
            getShortAddress() {
                const address = this.$store.getters.accountData.address
                if (address && address !== '') {
                    return `${address.substr(0, 7)}...${address.substr(address.length - 5, address.length)}`
                }
                return ''
            },
            getShortAuthorName() {
                if (this.author && this.author.name && this.author.name !== '') {
                    return this.author.name.length > 16 ? `${this.author.name.substr(0, 16)}...` : this.author.name
                }
                return ''
            }
        },
        async mounted() {
            await this.$store.dispatch('getAuthorRequestStatus', false)
            await this.getAuthorData()
            await this.checkRouter()
        },
        watch: {
            '$store.state.user.accountStatus'() {
                this.getAuthorData()
                this.checkRouter()
            },
            '$store.state.chain.accountData.address'() {
                this.$store.dispatch('getAuthorRequestStatus', false)
                this.getAuthorData()
            },
            $route() {
                this.checkRouter()
                this.showLinks = false
                this.mouseover = false
            }
        }
    }
</script>

<style scoped>
#app {
  font-family: 'inter';
  color: white;
  overflow-x: hidden;
  min-height: 100vh;
}
.img-profile {
  max-width: 48px;
  max-height: 48px;
  border-radius: 50%;
  object-fit: cover;
  width: 48px;
  height: 48px;
}
nav .nav-link a {
  font-size: 14px;
  color: white;
  margin-right: 2.5rem;
}
nav .nav-link a:hover {
  color: #EB1E4E;
}
.warpper {
  height: 100%;
}
nav a.router-link-exact-active {
  color: #EB1E4E;
}
.links {
  position: absolute;
  top: 0;
  right: 48px;
  font-family: 'Inter';
  background: #000000;
  border: 2px solid #000000;
  border-radius: 15px;
  z-index: 2;
  padding: 20px;
  width: 220px !important;
}
.links a {
  color: #FFFFFF;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-top: 20px;
  text-decoration: none;
  display: block;
}
.links a:hover {
  color: #EB1E4E;
}
.account {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  padding-top: 0 !important;
}
.hr {
  border-top: 2px solid #FFFFFF;
  margin-top: 20px;
}
.address-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #AAAAAA;
}
</style>
