<template>
    <div>
        <div class="flex items-center">
            <div class="mr-8">
                <fa v-if="variant === 'success'" class="toast-icon-success" icon="circle-check" size="xl"/>
                <fa v-else-if="variant === 'error'" class="toast-icon-error" icon="circle-exclamation" size="xl"/>
            </div>
            <div class="toast-text" :class="[text.length > 40 ? 'toast-text-small' : 'toast-text-big']">
                {{text}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            variant: {
                type: String,
                default: 'primary'
            },
            text: {
                type: String,
                default: null
            }
        }
    }
</script>

<style scoped>
.toast-icon-success {
  color: #1BEB87;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3), inset 6px 6px 20px rgba(0, 0, 0, 0.25);
}
.toast-icon-error {
  color: #EB1B4E;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3), inset 6px 6px 20px rgba(0, 0, 0, 0.25);
}
.toast-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #FFFFFF;
}
.toast-text-small {
  font-size: 12px;
}
.toast-text-big {
  font-size: 18px;
}
</style>
<style>
.Vue-Toastification__toast {
  padding: 18px 20px 18px 12px !important;
  background: rgba(28, 35, 47, 0.5) !important;
  box-shadow: 6px 6px 9px rgba(0, 0, 0, 0.25), inset 20px 20px 40px rgba(0, 0, 0, 0.25) !important;
  backdrop-filter: blur(100px) !important;
  border-radius: 7px !important;
}
.Vue-Toastification__toast--success {
  background: linear-gradient(to left bottom, #1BEB8730, #1C232F80 70%) !important;
}
.Vue-Toastification__toast--error {
  background: linear-gradient(to left bottom, #EB1B4E40, #1C232F80 60%) !important;
}
</style>
