import Vue from 'vue'
import Vuex from 'vuex'


import WalletConnect from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'
import chain from '@/store/chain'
import app from '@/store/app'
import user from '@/store/user'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    chain,
    user,
    app
  },
  strict: process.env.DEV
})

async function reconnectWallet() {

  const lastConnection = localStorage.getItem('last_connection')
  if (!lastConnection) return

  if (lastConnection === 'window.ethereum') {

    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({method: 'eth_accounts'})
        if (accounts.length > 0) {
          await store.dispatch('connectMetamask')
        }
      } catch (e) {
        Vue.prototype.$printError('There was an error connecting to Metamask 2. Please refresh your website!')
      }
    }

  } else if (lastConnection === 'wc') {

    const connector = new WalletConnect({
      bridge: 'https://bridge.walletconnect.org',
      qrcodeModal: QRCodeModal
    })

    if (!connector.connected)  return

    await store.dispatch('connectWalletConnect')
  }

}

reconnectWallet()

export default store
